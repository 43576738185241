import {
  Fragment,
  memo,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Button } from "../ui/button";
import {
  Command,
  CommandCategory,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { ChevronDown, ChevronUp, X } from "lucide-react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";
import { t } from "i18next";
import { cn } from "@/lib/utils";

export type GroupItemType<T> = {
  id: T;
  label: string;
};

export type GroupType<T> = {
  groupId: string;
  groupLabel: string;
  items: GroupItemType<T>[];
};

export type GroupsCategoryType<T> = {
  categoryId: string;
  categoryLabel: string;
  items: GroupType<T>[];
};

type CustomMultiSelectDataType<T> = {
  onGetValuesByClear?: () => T[];
  className?: string;
  placeholder?: string;
  defaultSelectedValues: T[];
  disabledValues?: T[];
  data: (GroupType<T> | GroupItemType<T> | GroupsCategoryType<T>) [];
  onChange: (selectedValues: T[]) => void;
  onBlur?: () => void;
  disabled: boolean;
  TriggerContentComponent?: React.ComponentType<{
    isOpen: boolean;
    selectedItems: { id: T; label: string; }[];
    noActiveValuesLabel?: string;
  }>;
  clearSelectionBtnLabel?: string
  onOpenClose?: (open: boolean) => void;
};

const CustomMultiSelect = <T extends string>({
  onGetValuesByClear,
  className = "",
  placeholder = "",
  defaultSelectedValues,
  disabledValues = [],
  data,
  disabled,
  onChange,
  TriggerContentComponent,
  clearSelectionBtnLabel = t("components.multiSelect.clearTagsBtn"),
  onOpenClose,
}: CustomMultiSelectDataType<T>) => {
  const [tagsData] = useState(data);
  const [selectedIds, setSelectedIds] = useState<T[]>(defaultSelectedValues); 
  const [open, setOpen] = useState(false);
  const [selectedCategory] = useState<string | null>(null);
  const [search, setSearch] = useState("");

  const selectedItems = useMemo(() => {
    const names: { id: T; label: string; } [] = [];

    if (selectedIds.length === 0) return names;

    tagsData.forEach((tagsDataEntry) => {
      if ((tagsDataEntry as any).categoryId) {
        (tagsDataEntry as GroupsCategoryType<T>).items.forEach((groupItem) => {
          groupItem.items.forEach((dataItem) => {
            if (selectedIds.includes(dataItem.id)) {
              names.push(dataItem);
            }
          })
        })
      } else if ((tagsDataEntry as any).groupId) {
        (tagsDataEntry as unknown as GroupType<T>).items.forEach((dataItem) => {
          if (selectedIds.includes(dataItem.id)) {
            names.push(dataItem);
          }
        })
      } else {
        if (selectedIds.includes((tagsDataEntry as GroupItemType<T>).id)) {
          names.push(tagsDataEntry as GroupItemType<T>);
        }
      }
    });

    return names;
  }, [selectedIds, tagsData]);


  const onCheckClick = useCallback((e: any) => {
    const { id, dataset: { state } } = e.target;
    const newSelectedIds = (state === "checked")
     ? selectedIds.filter((tagId) => tagId !== id)
     : selectedIds.concat(id);

    setSelectedIds(newSelectedIds);
    onChange(newSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const onClearAllClick: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    const newSelectedIds = onGetValuesByClear ? onGetValuesByClear() : [];
    setSelectedIds(newSelectedIds);
    onChange(newSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = (open: boolean) => {
    setOpen(open);

    if (onOpenClose) onOpenClose(open);
  };

  const renderCommandItem = (itemdData: GroupItemType<T>) => (
    <CommandItem key={itemdData.id}>
      <Checkbox
        disabled={disabledValues.includes(itemdData.id)}
        aria-label="select item"
        checked={selectedIds.includes(itemdData.id)}
        onClick={onCheckClick}
        id={itemdData.id}
        className="focus-visible:ring-0 mr-[8px] border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
      />
      <Label
        htmlFor={itemdData.id}
        className="w-full cursor-pointer font-normal text-sm text-[#11203D] border-0 border-slate-150"
      >
        {itemdData.label}
      </Label>
    </CommandItem>  
  );

  const renderCommandGroup = (groupData: GroupType<T>) => {
    return (
      <CommandGroup
        className="font-semibold text-sm text-[#11203D]"
        heading={groupData.groupLabel}
        key={groupData.groupId}
      >
        {groupData.items.map(renderCommandItem)} 
      </CommandGroup>
    );
  };

  const dataToIterate = tagsData;

  return (
    <Popover
      modal={true}
      open={open}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger
        className="flex focus-visible:ring-0"
        asChild
      >
        <Button
          disabled={disabled}
          className={cn("w-full justify-between", className)}
          role="combobox"
          variant="outline"
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          {TriggerContentComponent ? (
            <TriggerContentComponent
              noActiveValuesLabel={placeholder}
              selectedItems={selectedItems}
              isOpen={open}
            />
          ) : (
            <Fragment>
              <div className="w-full overflow-hidden mr-[16px] justify-start flex">
                {selectedItems.length === 0 ? (
                  <span className="text-muted-foreground">
                    {placeholder}
                  </span>
                ) : (
                  selectedItems.map((tagItem) => (
                    <span
                      className="bg-[#F1F4FA] mr-[8px] rounded-md px-[8px] py-[4px]"
                      key={tagItem.id as string}
                    >
                      {tagItem.label}
                    </span>
                  ))
                )}
              </div>
              {open ? (
                <ChevronUp
                  color="#11203D"
                  className="h-5 w-5 opacity-50"
                />
              ) : (
                <ChevronDown
                  color="#11203D"
                  className="h-5 w-5 opacity-50"
                />
              )}
            </Fragment>
          )}
        </Button>
      </PopoverTrigger>
      {/* max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] h-[450px] max-h-[450px]  */}
      <PopoverContent className="flex flex-col w-[377px] p-0 ">
        <Command
          onKeyDown={(e) => {
            if (e.key === 'Escape' && open) {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
            }
          }}
          className="flex w-full h-full"
        >
          <div className="flex w-full items-center">
            <CommandInput
              autoFocus
              value={search}
              onValueChange={setSearch}
              placeholder={`${placeholder} ${!!selectedCategory ? "or press \"Backspace\" to return" : ""}`}
            />
            <Button
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
              variant="ghost"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
          <CommandList>
            <CommandEmpty>
              {t("components.multiSelect.notFound")}
            </CommandEmpty>
            {
              dataToIterate.map((dataChunk) => {
                if ((dataChunk as any).categoryId) {
                  return (
                    <CommandCategory
                      categoryLabel={(dataChunk as GroupsCategoryType<T>).categoryLabel}
                      className="font-normal text-md text-[#11203D]"
                      key={(dataChunk as GroupsCategoryType<T>).categoryId}
                    >
                      {(dataChunk as GroupsCategoryType<T>).items.map(renderCommandGroup)}
                    </CommandCategory>
                  );
                }

                if ((dataChunk as any).groupId) {
                  return renderCommandGroup(dataChunk as GroupType<T>);
                }

                return renderCommandItem(dataChunk as GroupItemType<T>);
              })
            }
          </CommandList>
        </Command>
        <Button
          className="focus-visible:ring-0"
          onClick={onClearAllClick}
          variant="outline"
        >
          {clearSelectionBtnLabel}
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default memo(CustomMultiSelect);
